<template>
    <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
            <v-card-text>
                <v-row>
                    <v-col style="text-align: start;">
                        <span>{{text.copyright}}</span>
                    </v-col>
                    <v-col >
                        <v-btn text :to="{ path: '/imprint', hash: '#imprint-container'}">IMPRESSUM</v-btn>
                    </v-col>
                    <v-col style="text-align: end;">
                        <v-btn icon x-large href="https://www.facebook.com/kkbevent" target="_blank"><v-icon x-large>mdi-facebook</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>

const text = {
    copyright: "© 2023 by Sebastian Kunz - KKB-Event"
}

export default {
    data () {
        return {
            text: text,
        }
    },
    components: {
    }
}

</script>