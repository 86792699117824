<template>
    <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-card id="header" fluid center class="pa-0 ma-0" height="100px" width="var(--content-width)" elevation="0">
            <v-card id="logo-container" width="200" height="200" elevation="0" class="mt-2 pa-1 ml-4">
            <v-img id="logo" :src="require('@/assets/image/kkb.png')" alt="kkb-event"></v-img>
            </v-card>
            <v-container id="slogan" class="ma-0 pa-0 ml-10">
                <b style="font-size:20px;">feel the power of light</b>
            </v-container>
            <v-container id="nav-container" class="ma-0 pa-0 ml-10">
                <v-btn color="var(--kkb-green)" text v-for="nav in navigation" :to="{ path: '/', hash: nav.hash }" :key="nav.hash">{{nav.title}}</v-btn>
            </v-container>
        </v-card>
    </v-row>
</template>

<script>

export default {
  name: 'top',
  data () {
    return {
    }
    },
  components: {
  },
  methods: {
  },
  props: {
    navigation: Array
  }
}
</script>


<style>
#header {
    width:100%;
    height:100px;
    background-color:black;
    z-index: 2;
    border-radius: 0%;
}

#header-content {
    width: 1000px;
    height: 100px;
}

#logo-container {
    background-color:rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

#logo {
    border-radius: 0%;
    -webkit-filter: invert(1);
    filter: invert(1);
}

#slogan {
    position: relative;
    top: -200px;
    left: -180px;
    color: var(--kkb-green);
}

#nav-container {
    position: relative;
    top: -175px;
    left: 100px;
}

.nav-btn {
    margin-left: 10px;
    margin-right: 10px;
    color: var(--kkb-green);
}

.nav-btn:hover {
    color: yellow;
}

</style>