<template>
    <div id="main">
        <Top class="main-part-transparent" id="home" @selected="scrollTo"></Top>
        <Service class="main-part" id="service" @selected="scrollTo"></Service>
        <Over class="main-part" id="over"></Over>
        <Spacer class="main-part"></Spacer>
        <Impressions class="main-part" id="impressions"></Impressions>
        <Rental class="main-part" id="rental"></Rental>
        <Contact class="main-part" id="contact"></Contact>
        <VideoBackground id="bg-video" :src="require('@/assets/video/kkb_low.mp4')" :poster="require('@/assets/image/bg_start.jpeg')">
            <div id="bg-overlay"></div>
        </VideoBackground>
    </div>
</template>

<script>

import VideoBackground from 'vue-responsive-video-background-player'

import Top from '../components/Top.vue'
import Service from '../components/Service.vue'
import Over from '../components/Over.vue'
import Spacer from '../components/Spacer.vue'
import Impressions from '../components/Impressions.vue'
import Rental from '../components/Rental.vue'
import Contact from '../components/Contact.vue'

export default {
  components: {
    VideoBackground,
    Top,
    Service,
    Over,
    Spacer,
    Impressions,
    Rental,
    Contact,
  },
  methods: {
    scrollTo(sectionName) {
      console.log(sectionName);
      this.$vuetify.goTo(`#${sectionName}`, {offset: 100})
    },
  }
}

</script>

<style>
  .main-part {
    background: white;
    position: relative;
    z-index: 1;
    padding-top: 50px;
  }

  .main-part-transparent {
    position: relative;
    background: transparent;
    z-index: 1;
  }

  #bg-video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    max-height: 800px; 
    height: 800px;
    background-color: black;
  }
  #bg-overlay {
    width: 100%;
    height:100%;
    background-image: url("../assets/image/overlay.png");
    background-repeat: repeat;
  }

  #heading-line {
    height: 10px;
    width: 150px;
    background-color: var(--kkb-green);
    margin: auto;
    margin-top: 10px;
    
}
</style>