<template>
  <v-row align="center" justify="center" class="pa-0 ma-0">
    <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
      <v-card-text>
        <h1>{{text.over.heading}}</h1>
        <div id="heading-line"></div>
      </v-card-text>
      <v-card-text fluid>
        <v-row>
          <v-col>
            <span class="info-text">{{text.over.text}}</span>
          </v-col>
          <v-col v-if="!$router.isMobile">
            <v-parallax height="500" :src="require('@/assets/image/kkb_work.jpg')"></v-parallax>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>

const text = {
  over : {
    heading: "ÜBER UNS",
    text: "Seit 2011 betreuen wir unsere Kunden im Bereich Veranstaltungstechnik und sind dort primär im Full-Service Bereich, aber auch als Subdienstleister und Vermieter (Dry-Hire) tätig. Wir betreuen kleine bis mittelgroße Veranstaltungen von deren Konzeption über ihre Betreuung, bis zum Auf- und Abbau unserer Technik.  Durch eine enge Zusammenarbeit mit unseren Kunden, unserem stetig wachsenden Technikpool und kreativen Lösungen, können wir auf viele erfolgreiche Veranstaltungen in Deutschland, aber auch auf internationaler Ebene, zurückblicken.  Zu unserem Portfolio gehören u.a. die Realisierung von Traversenaufbauten, Gebäudeilluminationen, die Programmierung von Licht-, Laser- und Multimediashows sowie das Bedienen von Audiotechnik.",
  }
}

export default {
    data () {
        return {
            text: text,
        }
    },
    components: {
    }
}

</script>


<style>

</style>