import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Imprint from '../views/Imprint.vue'
import Pool from '../views/Pool.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/pool/:type',
    name: 'Pool',
    component: Pool,
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { 
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: isMobile() ? 50 : 100 }
      };
    }
  return { x: 0, y: 0 }
  },
})

function isMobile() {
  if(/Android|webOsS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

router.isMobile = isMobile()

export default router
