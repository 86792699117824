<template>
    <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
            <v-card-text>
                <h1>LEISTUNGEN</h1>
                <div id="heading-line"></div>
            </v-card-text>
            <v-card-text fluid>
                <v-row>
                    <v-col :cols="$router.isMobile ? 12 : 6" class="mb-5">
                        <h2 class="center mb-2">{{text.fullService.heading}}</h2>
                        <span class="center">{{text.fullService.text}}</span>
                    </v-col>
                    <v-col :cols="$router.isMobile ? 12 : 6" class="mb-5">
                        <h2 class="center mb-2">{{text.dryHire.heading}}</h2>
                        <span class="center">{{text.dryHire.text}}</span>
                        <v-row justify="center" class="mt-4 ml-2 mr-2"><v-btn block rounded color="var(--kkb-green)" :to="{ path: '/', hash: '#rental' }">VERMIETPOOL</v-btn></v-row>
                    </v-col>
                    <v-col :cols="$router.isMobile ? 12 : 6" class="mb-5">
                        <h2 class="center mb-2">{{text.lasershow.heading}}</h2>
                        <span class="center">{{text.lasershow.text}}</span>
                    </v-col>
                    <v-col :cols="$router.isMobile ? 12 : 6" class="mb-5">
                        <h2 class="center mb-2">{{text.personal.heading}}</h2>
                        <span class="center">{{text.personal.text}}</span>
                        <v-row justify="center" class="mt-4 ml-2 mr-2"><v-btn block rounded color="var(--kkb-green)" :to="{ path: '/', hash: '#contact' }">ANFRAGE STARTEN</v-btn></v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>

const text = {
    fullService: {
        heading: "Full - Service",
        text: "In enger Zusammenarbeit mit Ihnen erarbeiten wir ein maßgeschneidertes Technikkonzept für Ihre Veranstaltung, welches wir anschließend in vollem Umfang realisieren. Hierzu zählt der Auf- und Abbau unserer Technik, sowie die Bedienung der Licht-, Audio- und Multimediatechnik während Ihres Events.",
    },
    dryHire: {
        heading: "Dry - Hire",
        text: "Neben unserem Full-Service Angebot besteht auch die Möglichkeit einzelne Geräte und Equipment zu mieten. Einen Auszug aus unserem aktuellen Vermietpool finden sie hier.",
    },
    lasershow: {
        heading: "Lasershow",
        text: "Lasershows bieten wir Ihnen in den zwei bekannten Varianten an. Zum einen als Beamshow, bei der mit Hilfe von Nebel Muster in den Raum gezeichnet werden, wodurch diese zum Greifen nahe erscheinen. Zum anderen bieten wir Ihnen die Variante der Grafikshow, bei der die Laserstrahlung durch Projektionsleinwände oder Wasserleinwände auf einer zweidimensionalen Fläche sichtbar gemacht wird, um so Schriftzüge oder andere Muster abbilden zu können. Eine Mischung aus beiden Varianten ist selbstverständlich möglich. ",
    },
    personal: {
        heading: "Personal",
        text: "Wenn Sie auf der Suche nach temporärer, personeller Unterstützung im Bereich der Veranstaltungstechnik sind, bieten wir Ihnen diese durch unsere erfahrenen Techniker. Aktuelle Konditionen und weitere Informationen erhalten Sie auf Anfrage.",
    },
}

export default {
    data () {
        return {
            text: text,
        }
    },
    components: {
    },
    methods: {
        scrollTo(sectionName) {
            console.log(sectionName);
            this.$vuetify.goTo(`#${sectionName}`, {offset: 100})
        }   
    }
}

</script>

<style>
.td-left {
    padding-right: 10px;
}

.center {
    text-align: center;
}

</style>