<template>
    <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-card color="white" class="pa-0 rounded-0  pt-10" elevation="0" width="var(--content-width)">
            <v-card v-if="!$router.isMobile" height="80px" elevation="0"></v-card>
            <v-card-text class="pa-0 mt-8">
                <h1>Vermietpool</h1>
                <div id="heading-line"></div>
            </v-card-text>
            <v-card-text>
                <v-tabs show-arrows centered grow v-model="active" color="var(--kkb-green)" class="mb-5">
                    <v-tab v-for="category of fixtures" :key="category.id">{{category.name}}</v-tab>
                </v-tabs>
                <v-row class="mb-1" :key="active" justify="center" align="center">
                    <v-card v-for="fixture in fixtures[active].items" :key="active + fixture.name" width="220" height="250" class="ma-1">
                        <v-img height="130" contain :src="fixture.image ? require(`@/assets/image/pool/` + fixture.image) : require(`@/assets/image/kkb.png`)" class="mt-1"></v-img>
                        <v-card-title class="justify-center" style="font-size: 0.8em"><b>{{fixture.name}}</b></v-card-title>
                        <v-card-subtitle>{{fixture.title}}</v-card-subtitle>
                        <v-card-text>{{fixture.description}}</v-card-text>
                    </v-card>
                </v-row>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>
import json from '../assets/data/pool.json'

export default {
    data() {
        return {
            active: 0,
            fixtures: json
        }
    },
    created(){
        this.active = ['rigging', 'light', 'audio', 'video', 'signal'].indexOf(this.$route.params.type)
    }
}
</script>


<style>
p, h2, h3, a {
    text-align: left;
    margin-bottom: 0px;
    margin-top: 0px;
}
.tab-container{
    color: red;
}

#tabs {
    position: relative;

}
</style>