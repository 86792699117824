<template>
  <v-row align="center" justify="center" class="pa-0 ma-0">
    <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
      <v-card-text>
        <h1>{{heading}}</h1>
        <div id="heading-line"></div>
      </v-card-text>
      <v-card-text class="">
        <v-row>
          <v-carousel cycle height="500" hide-delimiter-background>
          <v-carousel-item v-for="(image, i) in images" :key="i">
            <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-img :src="require(`@/assets/image/slide/${image.imgName}`)" contain max-height="500">
                  <v-card color="rgba(255,255,255,0.5)" class="img-text-container pb-6">
                    <v-card-text class="ma-0 pa-0 img-text"><b>{{image.title}}</b></v-card-text>
                    <v-card-text class="ma-0 pa-0 pb-1">{{image.text}}</v-card-text>
                  </v-card>
                </v-img>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>

const heading = "IMPRESSIONEN"

const images = [
  {
    imgName: "IMG_1128.jpeg",
    title: "Faschingsparty",
    text: "Turnverein Busenbach (TVB) - 2019",
  },
  {
    imgName: "IMG_1716.jpeg",
    title: "Bühnenaufbau",
    text: "Private Veranstaltung - 2019",
  },
  {
    imgName: "img_2111.jpeg",
    title: "Illumination und Bühnenaufbau",
    text: "Privater Geburtstag",
  },
]

export default {
    data () {
        return {
            images: images,
            heading: heading,
        }
    },
    components: {
    }
}

</script>

<style>

.img-text {
  color: white;
  text-align: center;
}

.img-text-container{
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>