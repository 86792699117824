<template>
  <v-card :height="windowHeight" class="d-flex align-center justify-center" color="transparent">
    <v-card-text>
        <span id="top-text">IHR EXPERTE FÜR VERANSTALTUNGSTECHNIK</span>
      <v-row>
        <v-col class="mt-10">
          <v-btn icon color="white" x-larges :to="{ path: '/', hash: '#service' }"><v-icon x-large>mdi-arrow-down-circle</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'top',
  components: {
  },
  data (){
    return {
      windowHeight: window.innerHeight
    }
  },
  props:{
    mobile: Boolean,
  },
  methods: {
    navClicked(navName){
        this.$emit('selected', navName)
    }
  }
}
</script>

<style >
  #top-text {
    position: relative;
    color: white;
    font-size: 2em;
  }
</style>
