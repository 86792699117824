<template>
  <v-row align="center" justify="center" class="pa-0 ma-0">
    <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
      <v-card-text>
        <h1>{{text.heading}}</h1>
        <div id="heading-line"></div>
      </v-card-text>
      <v-card-text>
          <!--<v-row>
            <l-map :zoom="zoom" :center="center" id="map">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="warehouseMarkerLatLng">
              <l-icon :icon-anchor="anchor">
                <div id="marker-content">
                  <div class="marker-headline">
                    <span>LAGER</span> 
                  </div>
                  <img id="marker-image" style="width:50px; height:80px;" :src="require('../assets/image/kkb_marker.png')">
                </div>
              </l-icon>
            </l-marker>
            <l-marker :lat-lng="officeMarkerLatLng">
              <l-icon :icon-anchor="anchor">
                <div id="marker-content">
                  <div class="marker-headline">
                    <span>BÜRO</span> 
                  </div>
                  <img id="marker-image" style="width:50px; height:80px;" :src="require('../assets/image/kkb_marker.png')">
                </div>
              </l-icon>
            </l-marker>
            
          </l-map>
        </v-row>-->
        <v-row class="mt-5">
            <v-col :cols="!$router.isMobile ? 6 : 12" class="mt-5">
              <v-card elevation="0">
                <v-card-text class="pa-0"><h2 style="text-align:center;">{{text.request.heading}}</h2></v-card-text>
                <v-card-text class="pa-0"><span class="info-text">{{text.request.text}}</span></v-card-text>
              </v-card>
              <v-container>
                <v-card id="input-container" v-if="!mail.sent" :key="mail.sent" class="pa-0 mt-2" elevation="0" :disabled="mail.loading">
                  <v-card-text class="pa-0">
                    <v-text-field label="Name *" placeholder="Name" filled rounded dense clearable hide-details class="mb-2" v-model="mail.name"  :error-messages="mail.nameErr"></v-text-field>
                    <v-text-field label="Email-Adresse *" placeholder="Email-Adresse" filled rounded dense clearable hide-details class="mb-2" v-model="mail.mail" :error-messages="mail.mailErr"></v-text-field>
                    <v-text-field label="Telefon" placeholder="Telefon" filled rounded dense clearable hide-details class="mb-2" v-model="mail.phone"></v-text-field>
                    <v-text-field label="Betreff" placeholder="Betreff" filled rounded dense clearable hide-details class="mb-2" v-model="mail.subject"></v-text-field>
                    <v-textarea label="Nachricht *" placeholder="Nachricht" filled rounded dense clearable hide-details rows="4" row-height="30" v-model="mail.message" :error-messages="mail.messageErr"></v-textarea>
                    <v-btn class="mt-2" color="var(--kkb-green)" block @click="sendMessage()">Anfrage senden</v-btn>
                  </v-card-text>
                </v-card>
                <v-container v-else fill-height fluid id="input-container-sent">
                  <v-row align="center" justify="center">
                    <v-col v-if="mail.loading">
                      <v-progress-circular :width="12" size="80" color="var(--kkb-green)" indeterminate></v-progress-circular>
                    </v-col>
                    <v-col v-else :style="'color:' + (!mail.success ? 'red;' : '')">
                      <v-icon size="70">{{mail.success ? 'mdi-email-check' : 'mdi-email-alert'}}</v-icon><br>
                      <span>{{mail.success ? text.mail.success : text.mail.failed}}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
            </v-col>
            <v-col class="mt-5" fill-height>
              <v-card elevation="0">
                <v-card-text class="pa-0"><h2 style="text-align:center;">{{text.address.heading}}</h2></v-card-text>
                <v-card-text class="pa-0"><span class="info-text">{{text.request.text}}</span></v-card-text>
              </v-card>
              <v-row class="mt-2">
                <v-col style="text-align:center;">
                  <h3 class="center">{{text.address.subheading1}}</h3>
                  <span  v-for="line in text.address.text2" :key="line" class="address-text">{{line}}<br></span>
                </v-col>
                <v-col style="text-align:center;">
                  <h3 class="center">{{text.address.subheading2}}</h3>
                  <span  v-for="line in text.address.text1" :key="line" class="address-text">{{line}}<br></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3 class="center">{{text.address.subheading3}}</h3>
                  <a :href="'mailto:'+text.address.mail" style="color:grey">{{'Mail: '+text.address.mail}}</a><br>
                  <span  v-for="line in text.address.text3" :key="line" class="address-text">{{line}}<br></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2 class="center">{{text.jobs.heading}}</h2>
                  <span>{{text.jobs.text}}<br></span>
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col>
                  <div id="heading-line"></div>
                  <h2 class="center">{{text.adoption}}</h2>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>

//import Vue from 'vue';
//import { LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet';
//import 'leaflet/dist/leaflet.css';

//Vue.component('l-map', LMap);
//Vue.component('l-tile-layer', LTileLayer);
//Vue.component('l-marker', LMarker);
//Vue.component('l-icon', LIcon);

const text = {
  heading : "KONTAKT",
  adoption : "Wir freuen uns auf Sie!",
  request : {
    heading: "Anfragen",
    text: "Für Anfragen jeglicher Art, stehen wir Ihnen telefonisch, per Mail, oder über das untenstehende Formular zur Verfügung.",
  },
  address : {
    heading: "So erreichen Sie uns",
    subheading1: "Lager-Anschrift",
    subheading2: "Post-Anschrift",
    subheading3: "Erreichbarkeit",
    text1: ['KKB-Event','Daimlerstraße 62','76337 Waldbronn'],
    text2: ['KKB-Event','Friedenstraße 11','76337 Waldbronn'],
    mail: "info@kkb-event.de",
    text3: ['Tel: +49 171 - 9973161']
  },
  jobs : {
    heading: "Jobs",
    text: "Wir sind ständig auf der Suche nach freien Mitarbeitern und Aushilfen. Informationen erhalten Sie auf Anfrage.",
  },
  mail: {
    success: 'Ihre Anfrage wurde erfolreich übermittelt.',
    failed: 'Bei der Übermittlung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nocheinmal oder wenden Sie sich direkt per Mail oder Telefon an uns.'
  },
}

export default {
  data () {
    return {
      text: text,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [48.92644, 8.48151],
      anchor: [50, 100],
      warehouseMarkerLatLng: [48.92432, 8.47677],
      officeMarkerLatLng: [48.92598, 8.48807],
      mail: {
        name: "",
        nameErr: "",
        mail: "",
        mailErr: "",
        phone: "",
        subject: "",
        message: "",
        messageErr: "",
        sent: false,
        success: false,
        loading: false,
      },
      formText: [],
    }
  },
  components: {
    //LMap,
    //LTileLayer,
    //LMarker,
    //LIcon,
  },
  methods: {
    async sendMessage(){
      this.mail.loading = true
      if (this.validateData()){
        this.mail.sent = true
        let formData = new FormData();
        formData.append('name', this.mail.name);
        formData.append('mail', this.mail.mail);
        formData.append('phone', this.mail.phone);
        formData.append('subject', this.mail.subject);
        formData.append('message', this.mail.message);
        const data = {body: formData, method: "post"}
        const response = await fetch("api/mail.php", data)
        if (response.status === 200){
          this.mail.success = true
          this.resetForm()
        }else{
          this.mail.success = false
        }
      }
      this.mail.loading = false
    },
    resetForm(){
      this.mail.name = ''
      this.mail.mail = ''
      this.mail.phone = ''
      this.mail.subject = ''
      this.mail.message = ''
    },
    validateData(){
      
      

      this.mail.mailErr = ""
      this.mail.nameErr = ""
      this.mail.messageErr = ""

      var error = false
      if (this.mail.name.trim().length == 0) {
        this.mail.nameErr = "Geben Sie Ihren Namen ein"
        error = true
      }
      if (!String(this.mail.mail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        this.mail.mailErr = "Geben Sie eine gültige Email Adresse ein"
        error = true
      }
      if (this.mail.message.trim().length == 0) {
        this.mail.messageErr = "Geben Sie eine Nachricht ein"
        error = true
      }

      this.mail.success = !error
      return this.mail.success
    }
  },
}

</script>

<style>
#contact-container{
  height:6000px;
}
#map { 
  height: 300px; 
  z-index: 1;
}
#form-text {
  color:var(--kkb-green);
  font-size: small;
  line-height: 0px;
  white-space: pre-wrap;
}
#marker-content {
  text-align: center;
  width: 100px;
  height: 100px;
}
.marker-headline {
  border-radius: 5px;
  width: 60%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
}
.text-block {
  text-align: justify;
	text-align-last: none;
}

.text-center {
  text-align: center;
}

.address-text {
  width: 100%;
  text-align: start;
}
a, a:link, a:visited, a:hover, a:active{ 
  text-decoration: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
#input-container-sent{
  height:415px;
  border-radius:15px;
  background: rgba(0,0,0,0.1);
}
</style>