<template>
    <v-app :max-width="windowWidth">
      <v-app-bar v-if="$router.isMobile" app dense elevation="4" color="rgba(0,0,0,0.7)">
        <v-app-bar-nav-icon @click="drawer=true" color="var(--kkb-green)"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-card id="logo-container-mobile" elevation="0" class="mt-15 pa-1">
          <v-img id="logo-mobile" :src="require('@/assets/image/kkb.png')" alt="kkb-event"></v-img>
        </v-card>
      </v-app-bar>
      <Header v-else id="header" ref="navigation" :navigation="navigation" @selected="scrollTo" class="pa-0"></Header>
      <v-navigation-drawer app fixed v-model="drawer" temporary color="black">
          <v-list nav>
              <v-list-item-group v-model="group" style="color:white;">
              <v-list-item v-for="nav in navigation" :key="nav.hash" :to="{ path: '/', hash: nav.hash}">
                  <v-list-item-icon>
                  <v-icon color="var(--kkb-green)">{{nav.icon ? nav.icon : 'mdi-circle-medium'}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title style="color:white;">{{nav.title}}</v-list-item-title>
              </v-list-item>
              </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <router-view id="main"></router-view>
      <Footer class="main-part" id="contact" :mobile="$router.isMobile"></Footer>
    </v-app>
</template>
<script>

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  data () {
    return {
      windowWidth: window.innerWidth,
      drawer: false,
      group: null,
      navigation: [
            {
                title: 'HOME',
                hash: '#home',
                icon: 'mdi-home',
            },
            {
                title: 'LEISTUNGEN',
                hash: '#service',
            },
            {
                title: 'ÜBER',
                hash: '#over',
            },
            {
                title: 'IMPRESSIONEN',
                hash: '#impressions',
            },
            {
                title: 'VERMIETPOOL',
                hash: '#rental',
            },
            {
                title: 'KONTAKT',
                hash: '#contact'
            },
        ]
    }
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    scrollTo(sectionName) {
      console.log(sectionName);
      this.$vuetify.goTo(`${sectionName}`, {offset: 100})
    },
  },
}

</script>


<style>
  *{
    margin: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #header{
    width: 100%;
    position: fixed;
    z-index: 2;
  }
  #main {
    overflow-y: hidden;
  }

  #logo-container-mobile {
    width:80px;
    height:80px;
    background-color:rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

#logo-mobile{
    border-radius: 0%;
    -webkit-filter: invert(1);
    filter: invert(1);
}

  .content {
    margin: auto;
    width: var(--content-width);
  }

  .heading {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 35px;
  }
  .center{
    text-align: center;
  }
  h2 {
      font-size: 20px;
  }
  .content-container {
    border-radius:0px;
  }
</style>


<style>
:root {
    --kkb-green: rgba(175, 230, 90, 1.0);
    --content-width: 1000px;
    --content-width-mobile: 100%;
}
</style>
