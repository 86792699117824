<template>
    <div id="spacer-container">
    </div>
</template>

<script>

export default {
    data () {
        return {
        }
    },
    components: {
    }
}
</script>

<style>
#spacer-container {
    background-color: var(--kkb-green);
    height: 100px;
    background-image: url('../assets/image/kkb_rigging.png');
    background-repeat: repeat-x;
    background-position: center; 
}
</style>
