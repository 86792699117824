<template>
  <v-row align="center" justify="center" class="pa-0 ma-0">
    <v-card color="white" class="pa-0 rounded-0 pt-10" elevation="0" width="var(--content-width)">
      <v-card-text>
        <h1>{{heading}}</h1>
        <div id="heading-line"></div>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col v-for="(nav, i) in rental" :key="i">
            <v-card elevation="0" :to="{ path: `/pool/${nav.type}`, hash: '' }">
              <div>
                <div class="rental-item">
                    <v-img contain max-width="80%" :src="require(`@/assets/image/${nav.img}`)" id="rental-img" class="mx-auto"></v-img>
                </div>
              </div>
              </v-card>
            <b id="rental-txt">{{nav.title}}</b>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
const heading = "VERMIETPOOL"
const rental = [
  {
    img: 'kkb_rigging.png',
    title: 'Rigging',
    type: 'rigging',
  },
  {
    img: 'kkb_light.png',
    title: 'Licht & Effekte',
    type: 'light',
  },
  {
    img: 'kkb_sound.png',
    title: 'Ton',
    type: 'audio',
  },
  {
    img: 'kkb_multimedia.png',
    title: 'Multimedia',
    type: 'video'
  },
  {
    img: 'kkb_power.png',
    title: 'Signal & Strom',
    type: 'signal',
  },
]

export default {
  data () {
    return {
      heading: heading,
      rental: rental,
    }
  },
  components: {
  }
}

</script>

<style>
#rental-container {
  height: 500px;
}
.rental-item {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: var(--kkb-green);
  margin: auto;
  display: flex;
  justify-content: center;
}
</style>